body {
    font-family: 'Barlow', sans-serif;
    font-weight: 300;
    
    background-color: #232129;
    color: #dedede;
    
    overflow-y: scroll;
}

h1, h2, h3, h4, h5, h6 {
    font-family: 'Fjalla One', sans-serif;
    text-transform: uppercase;
}

a {
    color: deepskyblue;
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
    color: deepskyblue;
}

a.cbs-cam-link {
    color: inherit !important;
    text-decoration: none !important;
}

b, strong {
    font-weight: 700;
}

.form-control, .form-control:active, .form-control:focus {
    background-color: rgba(0, 0, 0, 0.5);
    border: none;
    border-radius: 2px;
    border-bottom: 2px solid rgba(0, 0, 0, 0.2);
    color: #dedede;
}

.form-control:active, .form-control:focus {
    border-bottom: 2px solid #29a5ff;
    outline: none;
    box-shadow: none;
}

select.form-control {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M12 13.172l4.95-4.95 1.414 1.414L12 16 5.636 9.636 7.05 8.222z' fill='rgba(255,255,255,1)'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: right 5px center;
    background-size: 1.2em;
}

.btn-cbs, .btn-cbs:active, .btn-cbs:focus {
    color: #dedede;
    background-color: rgba(255, 255, 255, 0.1);
    border: none;
    border-bottom: 2px solid rgba(0, 0, 0, 0.3);
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
    outline: none;
    box-shadow: none;
}

.btn-cbs:hover {
    color: #ffffff;
}

.btn-cbs:active {
    background-color: rgba(255, 255, 255, 0.06);
}

.ri-icon {
    display: inline-block;
    vertical-align: middle;
    margin-top: -0.15em;
}

.cbs-top {
    background-color: #2c2a31;
    z-index: 1000;
}

.cbs-side-nav {
    background-color: #2c2a31;
    min-height: 100vh;
}

@media screen and (max-width: 992px) {
    .cbs-side-nav {
        position: fixed;
        z-index: 10001;
    }
}

@media screen and (max-width: 768px) {
    .cbs-side-nav {
        min-height: auto;
    }
}

.cbs-logo {
    background-image: url('/assets/logo_2022.svg');
    background-origin: content-box;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    min-height: 30px;
    max-height: 50px;
}

.cbs-cam {
    margin: 6px 1px;
    padding: 5px;
    border-radius: 5px;
    cursor: pointer;
    
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.cbs-cam:hover {
    background-color: rgba(255, 255, 255, 0.1);
}

.cbs-cam-thumb-container {
    display: block;
    position: relative;
    width: 100%;
    height: auto;
    aspect-ratio: 5 / 3;
    overflow: hidden;
    border-radius: 3px;
}

.cbs-cam-thumb {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    object-fit: cover;
    object-position: center;
}

.cbs-cam-grid {
    display: grid;
    grid-template-columns: repeat(1, 100%);
}

@media screen and (min-width: 576px) {
    .cbs-cam-grid {
        grid-template-columns: repeat(2, 50%);
    }
}

@media screen and (min-width: 992px) {
    .cbs-cam-grid {
        grid-template-columns: repeat(4, 25%);
    }
}

@media screen and (min-width: 1800px) {
    .cbs-cam-grid {
        grid-template-columns: repeat(5, 20%);
    }
}

@media screen and (min-width: 2000px) {
    .cbs-cam-grid {
        grid-template-columns: repeat(6, 16.6%);
    }
}

.cbs-cam-age, .cbs-cam-new, .cbs-cam-hd, .cbs-cam-show {
    position: absolute;
    display: inline-block;
    background-color: rgba(0, 0, 0, 0.7);
    padding: 3px 8px 4px 8px;
    vertical-align: middle;
    font-size: 10pt;
    font-weight: 400;
    line-height: 100%;
    border-radius: 15px;
}

.cbs-cam-age {
    right: 5px;
    bottom: 5px;
}

.cbs-cam-new {
    bottom: 5px;
    left: 5px;
    font-size: 8pt;
    border-radius: 3px;
    background: rgb(29,191,72);
    background: linear-gradient(0deg, rgba(29,191,72,1) 0%, rgba(150,252,116,1) 100%);
    color: rgba(0, 0, 0, 0.5);
    padding: 2px 4px;
    font-weight: 700;
}

.cbs-cam-hd {
    top: 5px;
    right: 5px;
    font-size: 8pt;
    border-radius: 3px;
    padding: 2px 4px;
}

.cbs-cam-show {
    top: 5px;
    left: 5px;
    font-size: 8pt;
    border-radius: 3px;
    padding: 2px 4px;
    text-transform: uppercase;
}

.cbs-cam-username {
    font-weight: 600;
    font-size: 13pt;
    color: #90d2fe;
}

.cbs-cam-username, .cbs-cam-detail {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.cbs-cam-detail {
    width: 100%;
    font-size: 9pt;
    font-weight: 300;
}

.cbs-small-title {
    text-transform: uppercase;
    font-family: 'Fjalla One', sans-serif;
    font-size: 9pt;
    color: rgba(255, 255, 255, 0.5);
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.cbs-small-title .ri-icon {
    font-size: 12pt;
    margin-top: -0.1em;
}

.cbs-tag {
    display: inline-block;
    font-size: 11px;
    background-color: rgba(0, 0, 0, 0.5);
    background: linear-gradient(0deg, rgba(112,114,115,0) 0%, rgba(100, 101, 102, 0.5) 100%);
    margin-right: 2px;
    padding: 2px 4px;
    border-radius: 15px;
    font-weight: 400;
    vertical-align: middle;
}

.cbs-tag.big {
    font-size: 13px;
    padding: 4px 6px;
    cursor: pointer;
    margin-right: 5px;
    border-radius: 3px;
}

.cbs-tag.big:hover {
    filter: brightness(150%);
}

.cbs-top-icon {
    font-size: 18pt;
    color: #dedede;
    text-decoration: none;
}

.cbs-top-icon:hover {
    color: #ffffff;
}

.cbs-side-item {
    font-size: 14pt;
    font-weight: 200;
    padding: 0.2em 0.5em 0.1em 0.5em;
    border-left: 6px solid transparent;
    
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    
    cursor: pointer;
    
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.cbs-side-item .ri-icon {
    font-size: 22pt;
    margin-top: -0.1em;
    margin-right: 0.3em;
}

.cbs-side-item:hover {
    background-color: rgba(255, 255, 255, 0.05);
}

.cbs-side-item.active {
    background-color: rgba(255, 255, 255, 0.1);
    border-left: 6px solid rgba(0, 0, 0, 0.6);
}

.cbs-side-link, .cbs-side-link:hover {
    color: #dedede;
    text-decoration: none;
}

.cbs-container {
    padding: 1em;
    background-color: rgba(255, 255, 255, 0.05);
}

.cbs-category {
    font-family: 'Barlow', sans-serif;
    font-weight: 700;
    text-transform: none;
    font-size: 32pt;
}

.cbs-category-badge {
    display: inline-block;
    font-size: 12pt;
    margin: 3px 5px;
    font-weight: 700;
    background-color: rgba(0, 0, 0, 0.3);
    padding: 2px 15px;
    border-radius: 15px;
    border-bottom: 3px solid rgba(0, 0, 0, 0.5);
}

.cbs-category-badge:hover {
    background-color: rgba(255, 255, 255, 0.05);
}

.cbs-category-badge[data-active="true"] {
    background-color: rgba(255, 255, 255, 0.2);
    border-bottom: 3px solid rgba(0, 0, 0, 0.3);
}

.cbs-overflow-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
}

@media screen and (max-width: 1200px) {
    .cbs-overflow-grid {
        grid-template-columns: repeat(2, 1fr);
    }
}

.cbs-overflow-info {
    display: inline-block;
    vertical-align: middle;
    margin-left: 3px;
    padding: 1px 3px 2px 3px;
    font-size: 9pt;
    background-color: rgba(0, 0, 0, 0.2);
    line-height: 100%;
    border-bottom: 1px solid rgba(0, 0, 0, 0.5);
    border-radius: 3px;
}