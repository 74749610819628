body {
  color: #dedede;
  background-color: #232129;
  font-family: Barlow, sans-serif;
  font-weight: 300;
  overflow-y: scroll;
}

h1, h2, h3, h4, h5, h6 {
  text-transform: uppercase;
  font-family: Fjalla One, sans-serif;
}

a {
  color: #00bfff;
  text-decoration: none;
}

a:hover {
  color: #00bfff;
  text-decoration: underline;
}

a.cbs-cam-link {
  color: inherit !important;
  text-decoration: none !important;
}

b, strong {
  font-weight: 700;
}

.form-control, .form-control:active, .form-control:focus {
  color: #dedede;
  background-color: #00000080;
  border: none;
  border-bottom: 2px solid #0003;
  border-radius: 2px;
}

.form-control:active, .form-control:focus {
  box-shadow: none;
  border-bottom: 2px solid #29a5ff;
  outline: none;
}

select.form-control {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' width='24' height='24'%3E%3Cpath fill='none' d='M0 0h24v24H0z'/%3E%3Cpath d='M12 13.172l4.95-4.95 1.414 1.414L12 16 5.636 9.636 7.05 8.222z' fill='rgba(255,255,255,1)'/%3E%3C/svg%3E");
  background-position: right 5px center;
  background-repeat: no-repeat;
  background-size: 1.2em;
}

.btn-cbs, .btn-cbs:active, .btn-cbs:focus {
  color: #dedede;
  box-shadow: none;
  background-color: #ffffff1a;
  border: none;
  border-bottom: 2px solid #0000004d;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
  outline: none;
}

.btn-cbs:hover {
  color: #fff;
}

.btn-cbs:active {
  background-color: #ffffff0f;
}

.ri-icon {
  vertical-align: middle;
  margin-top: -.15em;
  display: inline-block;
}

.cbs-top {
  z-index: 1000;
  background-color: #2c2a31;
}

.cbs-side-nav {
  min-height: 100vh;
  background-color: #2c2a31;
}

@media screen and (max-width: 992px) {
  .cbs-side-nav {
    z-index: 10001;
    position: fixed;
  }
}

@media screen and (max-width: 768px) {
  .cbs-side-nav {
    min-height: auto;
  }
}

.cbs-logo {
  min-height: 30px;
  max-height: 50px;
  background-image: url("/assets/logo_2022.svg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  background-origin: content-box;
}

.cbs-cam {
  cursor: pointer;
  -webkit-touch-callout: none;
  user-select: none;
  -khtml-user-select: none;
  border-radius: 5px;
  margin: 6px 1px;
  padding: 5px;
}

.cbs-cam:hover {
  background-color: #ffffff1a;
}

.cbs-cam-thumb-container {
  width: 100%;
  height: auto;
  aspect-ratio: 5 / 3;
  border-radius: 3px;
  display: block;
  position: relative;
  overflow: hidden;
}

.cbs-cam-thumb {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.cbs-cam-grid {
  grid-template-columns: repeat(1, 100%);
  display: grid;
}

@media screen and (min-width: 576px) {
  .cbs-cam-grid {
    grid-template-columns: repeat(2, 50%);
  }
}

@media screen and (min-width: 992px) {
  .cbs-cam-grid {
    grid-template-columns: repeat(4, 25%);
  }
}

@media screen and (min-width: 1800px) {
  .cbs-cam-grid {
    grid-template-columns: repeat(5, 20%);
  }
}

@media screen and (min-width: 2000px) {
  .cbs-cam-grid {
    grid-template-columns: repeat(6, 16.6%);
  }
}

.cbs-cam-age, .cbs-cam-new, .cbs-cam-hd, .cbs-cam-show {
  vertical-align: middle;
  background-color: #000000b3;
  border-radius: 15px;
  padding: 3px 8px 4px;
  font-size: 10pt;
  font-weight: 400;
  line-height: 100%;
  display: inline-block;
  position: absolute;
}

.cbs-cam-age {
  bottom: 5px;
  right: 5px;
}

.cbs-cam-new {
  color: #00000080;
  background: linear-gradient(0deg, #1dbf48 0%, #96fc74 100%);
  border-radius: 3px;
  padding: 2px 4px;
  font-size: 8pt;
  font-weight: 700;
  bottom: 5px;
  left: 5px;
}

.cbs-cam-hd {
  border-radius: 3px;
  padding: 2px 4px;
  font-size: 8pt;
  top: 5px;
  right: 5px;
}

.cbs-cam-show {
  text-transform: uppercase;
  border-radius: 3px;
  padding: 2px 4px;
  font-size: 8pt;
  top: 5px;
  left: 5px;
}

.cbs-cam-username {
  color: #90d2fe;
  font-size: 13pt;
  font-weight: 600;
}

.cbs-cam-username, .cbs-cam-detail {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.cbs-cam-detail {
  width: 100%;
  font-size: 9pt;
  font-weight: 300;
}

.cbs-small-title {
  text-transform: uppercase;
  color: #ffffff80;
  border-bottom: 1px solid #fff3;
  font-family: Fjalla One, sans-serif;
  font-size: 9pt;
}

.cbs-small-title .ri-icon {
  margin-top: -.1em;
  font-size: 12pt;
}

.cbs-tag {
  vertical-align: middle;
  background: linear-gradient(0deg, #70727300 0%, #64656680 100%);
  border-radius: 15px;
  margin-right: 2px;
  padding: 2px 4px;
  font-size: 11px;
  font-weight: 400;
  display: inline-block;
}

.cbs-tag.big {
  cursor: pointer;
  border-radius: 3px;
  margin-right: 5px;
  padding: 4px 6px;
  font-size: 13px;
}

.cbs-tag.big:hover {
  filter: brightness(150%);
}

.cbs-top-icon {
  color: #dedede;
  font-size: 18pt;
  text-decoration: none;
}

.cbs-top-icon:hover {
  color: #fff;
}

.cbs-side-item {
  -webkit-touch-callout: none;
  user-select: none;
  -khtml-user-select: none;
  cursor: pointer;
  white-space: nowrap;
  text-overflow: ellipsis;
  border-left: 6px solid #0000;
  padding: .2em .5em .1em;
  font-size: 14pt;
  font-weight: 200;
  overflow: hidden;
}

.cbs-side-item .ri-icon {
  margin-top: -.1em;
  margin-right: .3em;
  font-size: 22pt;
}

.cbs-side-item:hover {
  background-color: #ffffff0d;
}

.cbs-side-item.active {
  background-color: #ffffff1a;
  border-left: 6px solid #0009;
}

.cbs-side-link, .cbs-side-link:hover {
  color: #dedede;
  text-decoration: none;
}

.cbs-container {
  background-color: #ffffff0d;
  padding: 1em;
}

.cbs-category {
  text-transform: none;
  font-family: Barlow, sans-serif;
  font-size: 32pt;
  font-weight: 700;
}

.cbs-category-badge {
  background-color: #0000004d;
  border-bottom: 3px solid #00000080;
  border-radius: 15px;
  margin: 3px 5px;
  padding: 2px 15px;
  font-size: 12pt;
  font-weight: 700;
  display: inline-block;
}

.cbs-category-badge:hover {
  background-color: #ffffff0d;
}

.cbs-category-badge[data-active="true"] {
  background-color: #fff3;
  border-bottom: 3px solid #0000004d;
}

.cbs-overflow-grid {
  grid-template-columns: repeat(4, 1fr);
  display: grid;
}

@media screen and (max-width: 1200px) {
  .cbs-overflow-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

.cbs-overflow-info {
  vertical-align: middle;
  background-color: #0003;
  border-bottom: 1px solid #00000080;
  border-radius: 3px;
  margin-left: 3px;
  padding: 1px 3px 2px;
  font-size: 9pt;
  line-height: 100%;
  display: inline-block;
}

/*# sourceMappingURL=index.789c1ccd.css.map */
